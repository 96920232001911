.hero-background-image {
  padding: 0px !important;

  width: 100%;
  background-color: "white" !important;
  display: block;

  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.background-top-layer {
  margin: 0px;
  width: 100% !important;
  height: 100% !important;
  background-color: "white";
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  padding-top: 250px;
}

.title {
  font-size: 60px !important;
  text-transform: uppercase;
  color: black;
  font-weight: 900;
}

.menu-image {
  max-width: 900px;

  height: auto;
  padding: 0;
  margin: 0;
}

@media (width <= 1250px) {
  .menu-image {
    max-width: 80%;

    height: auto;
    padding: 0;
    margin: 0;
  }

  .subtitle {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
